import './App.css';
import React from "react";
import Head from "./Components/Header/Header";
import OMnie from "./Components/OMnie/OMnie";
import Layout from "antd/es/layout";
import Education from "./Components/Doświadczenie/Education";
import Servises from "./Components/Servises/Servises";
import Kontakt from "./Components/Kontakt/Kontakt";
import {Col, Row, theme, Typography} from 'antd';



function App() {
    const {Header, Content, Footer} = Layout;
    const {
        token: {colorBgContainer},
    } = theme.useToken();

    const calculateImage = () => {
        if (window.width < 1440) {
            return 900
        }
        else return window.innerHeight
    };


    console.log(window.innerWidth)

    console.log(document.innerHeight, window.innerHeight)


    return (
        <>
            <Layout style={{
                background: colorBgContainer
            }}>
                <Header className='headerStyle' style={{height: calculateImage(window.innerHeight)}}>
                    <Head/>
                    <Row className='tempRow'>
                        <Col span={24} className='tempColumn'>
                            <div className='title'>Profesjonalne<br/>poszukiwania</div>
                            <div className='title2'>i lokalizowanie obiektów <br/> metalowych oraz podwodnych</div>
                        </Col>
                    </Row>
                </Header>
                <Content className={'contentSection'} style={{
                    background: colorBgContainer
                }}>
                    <OMnie/>
                    <Education/>
                    <Servises/>

                    <Row className='paragrafRow'>
                        <Col span={24} >
                            <Row justify='center' className='tempRow10' >
                                <Col lg={{span: 10}} sm={{span:24}} xs={{span: 24}} >
                                    <Typography className= 'paragraf'>
                                        Swoje usługi kieruję w stronę firm archeologicznych, budowlanych, policji, wojsku, straży
                                        pożarnej,
                                        nurkom i firmom detektywistycznym, a także profesjonalistom z branży akwakultury, poszukiwań i
                                        ratownictwa, oraz podwodnej inspekcji.
                                    </Typography>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Kontakt/>
                </Content>
                <Row className='tempRow11' >
                    <Col >
                    <span className='footer1'>Copyright 2023 </span> <span className='footer2'> Znajdeto.pl </span>
                </Col>
                </Row>
            </Layout>

        </>
    );
}

export default App;
